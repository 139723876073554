<template>
  <div>
    <CollectionPointForm
      v-if="addressStore.hasCollectionPoint"
      :api-errors="apiErrors"
      :birthdate="formValues.birthdate"
      :first-name="formValues.firstName"
      :last-name="formValues.lastName"
      :phone="formValues.phone"
      :submitting="isFormDisabled"
      :with-user-information="userInformationStore.isFormRequired"
      @submit="handleCollectionPointSubmit"
    />

    <ShippingAddressForm
      v-if="!addressStore.hasCollectionPoint"
      :address="formValues"
      :api-errors="apiErrors"
      :countries="countries"
      :country="countryCode"
      :submitting="isFormDisabled"
      :with-user-information="userInformationStore.isFormRequired"
      @submit="handleShippingAddressSubmit"
    >
      <template #default="{ country }">
        <RevDivider
          v-if="cartStore.hasUnshippableItemsForCountry(country)"
          class="my-16 md:my-24"
        />

        <UnavailableShippings
          v-if="cartStore.hasUnshippableItemsForCountry(country)"
          :address-country="country"
          class="mt-32"
          :loading="isRemovingItem"
          @remove="handleUnavailableShippingsRemove"
          @update="handleUnavailableShippingsUpdate"
        />
      </template>
    </ShippingAddressForm>

    <ReassuranceItems class="mt-56">
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { cartAPI } from '@backmarket/http-api'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevDivider } from '@ds/components/Divider'
import {
  getPhoneNumberInfos,
  getPhoneNumberToE164,
} from '@ds/components/InputPhone'

import { ROUTES } from '~/scopes/auth/route-names'

import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser.ts'
import { CHECKOUT } from '../../routes-names'
import { useAddressStore } from '../../stores/addressStore'
import { useCartStore } from '../../stores/cartStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useSwapStore } from '../../stores/swapStore'
import { useUserInformationStore } from '../../stores/userInformationStore'
import { getBouyguesAddressAPIValidationErrors } from '../../utils/getBouyguesAddressAPIValidationErrors'

import CollectionPointForm from './components/CollectionPointForm/CollectionPointForm.vue'
import ShippingAddressForm from './components/ShippingAddressForm/ShippingAddressForm.vue'
import UnavailableShippings from './components/UnavailableShippings/UnavailableShippings.vue'

import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems'

const isRemovingItem = ref(false)
const apiErrors = ref({})
const selectedShippings = ref({})

const router = useRouter()
const route = useRoute()
const logger = useLogger()
const tracking = useTracking()
const i18n = useI18n()

const {
  market: { countryCode },
} = useMarketplace()
const userStore = useUserStore()

const addressStore = useAddressStore()
const cartStore = useCartStore()
const userInformationStore = useUserInformationStore()
const loaderStore = useLoaderStore()
const swapStore = useSwapStore()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

onMounted(async () => {
  try {
    await cartStore.fetchShippings()
    tracking.trackFunnel(cartStore.trackingData(CHECKOUT.SHIPPING_ADDRESS))
  } catch {
    // Empty catch until we tackle the following issue: CK-3553
  }
})

const address = computed(() => {
  const shippingCountry = addressStore.shipping.country
  const hasUnshippableItemsForCountry =
    shippingCountry && cartStore.hasUnshippableItemsForCountry(shippingCountry)

  if (
    shippingCountry &&
    hasUnshippableItemsForCountry &&
    !addressStore.shippableCountries.some(
      (country) => country.countryCode === addressStore.shipping.country,
    )
  ) {
    return { country: countryCode }
  }

  return addressStore.hasCollectionPoint
    ? addressStore.collectionPoint
    : addressStore.shipping
})

const formValues = computed(() => {
  const phone = `${address.value.phone || userStore.user.phone || ''}`
  const countryDialInCode = `${address.value.countryDialInCode || userStore.user.countryDialInCode || ''}`

  logger.debug(
    '[Checkout] phone number parsing: ',
    phone && countryDialInCode
      ? getPhoneNumberToE164(countryDialInCode, phone)
      : phone,
  )

  return {
    ...address.value,
    country: address.value.country || countryCode || '',
    firstName: address.value.firstName || userStore.user.firstName || '',
    lastName: address.value.lastName || userStore.user.lastName || '',
    phone:
      phone && countryDialInCode
        ? getPhoneNumberToE164(countryDialInCode, phone)
        : phone,

    ...insertIf(userInformationStore.isFormRequired, {
      birthdate: userInformationStore.birthdate || '',
      nationalId: userInformationStore.nationalId,
    }),
  }
})

const countries = computed(() => {
  return [...addressStore.shippableCountries]
    .map(({ name, countryCode: shippableCountryCode }) => ({
      value: shippableCountryCode,
      label: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
})

const isFormDisabled = computed(() => {
  return loaderStore.isEnabled || isRemovingItem.value
})

const allShippingsSelected = computed(() => {
  return Object.values(selectedShippings.value).every(Boolean)
})

const safelyFetchShippings = async () => {
  if (!cartStore.hasAvailableItems) {
    router.push({ name: CHECKOUT.CART })

    return
  }

  try {
    await cartStore.fetchShippings()
  } catch (error) {
    const next = route.fullPath

    switch (error.status_code) {
      case 401:
        // User has been disconnected
        router.push({
          name: ROUTES.AUTH.LOGIN,
          query: { next },
        })
        break
      case 403:
        // HTTP 403 can occur when user is merchant or staff. We just ignore
        // that error here.
        break
      default:
        throw error
    }
  }
}

const handleUnavailableShippingsUpdate = (shippings) => {
  selectedShippings.value = shippings
}

const handleUnavailableShippingsRemove = async (item) => {
  isRemovingItem.value = true

  try {
    await $httpFetch(cartAPI.postUpdateQuantity, {
      body: {
        action: 'delete',
        listingId: item.listingId,
        newQuantity: item.quantity,
        listingPrice: item.price,
      },
    })

    await cartStore.fetchCart()
    await safelyFetchShippings()
  } catch (error) {
    // Do nothing, we monitor errors instead
  }

  isRemovingItem.value = false
}

const handleApiError = (error) => {
  Object.assign(
    apiErrors.value,
    getBouyguesAddressAPIValidationErrors(error, i18n),
  )

  const camelizedError = camelizeKeys(error)
  // FIXME Needs to update the httpApiError to manage endpointSettings.transformResponseToCamelCase
  Object.assign(
    apiErrors.value,
    Object.keys(camelizedError).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          Array.isArray(camelizedError[key]) && camelizedError[key].length > 0
            ? camelizedError[key][0]
            : '',
      }),
      {},
    ),
  )
}

const handleCollectionPointSubmit = async ({
  firstName,
  lastName,
  phone,
  birthdate,
}) => {
  loaderStore.enable()

  tracking.trackClick({
    name: '3_shipping-new-edit_cta_continue',
    zone: 'funnel',
  })

  try {
    const { dial, nationalNumber } = getPhoneNumberInfos(phone, countryCode)

    if (isEmpty(nationalNumber)) {
      logger.debug('[CHECKOUT] Failed to convert phone in shipping form', {
        phone,
      })
    }

    await addressStore.saveCollectionPoint({
      address: {
        ...address.value,
        firstName,
        lastName,
        phone: nationalNumber,
        countryDialInCode: dial,
        ...insertIf(userInformationStore.isFormRequired, {
          birthdate,
        }),
      },
      ...insertIf(userInformationStore.isFormRequired, {
        formType: userInformationStore.formType,
      }),
    })

    // Reload checkout store
    await cartStore.fetchCart()

    router.push({
      name: CHECKOUT.BILLING_ADDRESS,
    })
  } catch (error) {
    if (error?.status === 400) handleApiError(error)
    else
      await handleUnauthorizedUser(
        error,
        '[CHECKOUT] Failed to submit shipping form',
      )

    loaderStore.disable()
  }
}

const handleShippingAddressSubmit = async (values, sameAsShipping) => {
  apiErrors.value = {}

  if (
    !allShippingsSelected.value &&
    cartStore.hasUnshippableItemsForCountry(values.country)
  ) {
    return
  }

  loaderStore.enable()

  try {
    tracking.trackClick({
      name: '3_shipping-new-edit_cta_continue',
      zone: 'funnel',
    })

    // If needed, save shippings
    if (
      !isEmpty(selectedShippings.value) &&
      cartStore.hasUnshippableItemsForCountry(values.country)
    ) {
      await $httpFetch(cartAPI.postShippings, {
        body: Object.entries(selectedShippings.value).map(
          ([itemId, shipping]) => ({
            listingId: Number(itemId),
            shippingId: shipping.shippingId,
            shipperDisplay: shipping.shipperDisplay,
          }),
        ),
      })
    }

    const { dial, nationalNumber } = getPhoneNumberInfos(
      values.phone,
      values.country,
    )

    const shippingAddress = {
      ...values,
      phone: nationalNumber,
      countryDialInCode: dial,
    }

    if (isEmpty(nationalNumber)) {
      logger.debug('[CHECKOUT] Failed to convert phone in shipping form', {
        phone: values.phone,
      })
    }

    if (swapStore.hasOffer) {
      await addressStore.createBuybackAddress({
        ...shippingAddress,
      })
    }

    if (cartStore.hasBouyguesMobilePlan) {
      await addressStore.saveBouyguesAddress({
        isShipping: true,
        isBilling: sameAsShipping,
        address: shippingAddress,
      })
    }

    await addressStore.saveAddress({
      isShipping: true,
      isBilling: sameAsShipping,
      ...insertIf(userInformationStore.isFormRequired, {
        formType: userInformationStore.formType,
      }),
      address: shippingAddress,
    })

    // Reload checkout store
    await cartStore.fetchCart()

    router.push({
      name: sameAsShipping ? CHECKOUT.PAYMENT : CHECKOUT.BILLING_ADDRESS,
    })
  } catch (error) {
    if (error?.status === 400) handleApiError(error)
    else
      await handleUnauthorizedUser(
        error,
        '[CHECKOUT] Failed to submit shipping form',
      )

    loaderStore.disable()
  }
}
</script>
